interface TableIndoToHebrew {
    readonly   [index: number]: string;
}

interface TableHebrewToIndo {
    readonly [index: string]: number;
}

export const indoArabicToHebrewCardinal = (indo_arabic_number: number): string => {
    const table: TableIndoToHebrew = {
        1: 'א׳',
        2: 'ב׳',
        3: 'ג׳',
        4: 'ד׳',
        5: 'ה׳',
        6: 'ו׳',
        7: 'ז׳',
        8: 'ח׳',
        9: 'ט׳',
        10: 'י׳',
        11: 'י״א',
        12: 'י״ב',
        13: 'י״ג',
        14: 'י״ד',
        15: 'ט״ו',
        16: 'ט״ז',
        17: 'י״ז',
        18: 'י״ח',
        19: 'י״ט',
        20: 'כ׳',
        21: 'כ״א',
        22: 'כ״ב',
        23: 'כ״ג',
        24: 'כ״ד',
        25: 'כ״ה',
        26: 'כ״ו',
        27: 'כ״ז',
        28: 'כ״ח',
        29: 'כ״ט',
        30: 'ל׳',
        31: 'ל״א',
        32: 'ל״ב',
        33: 'ל״ג',
        34: 'ל״ד',
        35: 'ל״ה',
        36: 'ל״ו',
        37: 'ל״ז',
        38: 'ל״ח',
        39: 'ל״ט',
        40: 'מ׳',
        41: 'מ״א',
        42: 'מ״ב',
        43: 'מ״ג',
        44: 'מ״ד',
        45: 'מ״ה',
        46: 'מ״ו',
        47: 'מ״ז',
        48: 'מ״ח',
        49: 'מ״ט',
        50: 'נ׳',
        51: 'נ״א',
        52: 'נ״ב',
        53: 'נ״ג',
        54: 'נ״ד',
        55: 'נ״ה',
        56: 'נ״ו',
        57: 'נ״ז',
        58: 'נ״ח',
        59: 'נ״ט',
        60: 'ס׳',
        61: 'ס״א',
        62: 'ס״ב',
        63: 'ס״ג',
        64: 'ס״ד',
        65: 'ס״ה',
        66: 'ס״ו',
        67: 'ס״ז',
        68: 'ס״ח',
        69: 'ס״ט',
        70: 'ע׳',
        71: 'ע״א',
        72: 'ע״ב',
        73: 'ע״ג',
        74: 'ע״ד',
        75: 'ע״ה',
        76: 'ע״ו',
        77: 'ע״ז',
        78: 'ע״ח',
        79: 'ע״ט',
        80: 'פ׳',
        81: 'פ״א',
        82: 'פ״ב',
        83: 'פ״ג',
        84: 'פ״ד',
        85: 'פ״ה',
        86: 'פ״ו',
        87: 'פ״ז',
        88: 'פ״ח',
        89: 'פ״ט',
        90: 'צ׳',
        91: 'צ״א',
        92: 'צ״ב',
        93: 'צ״ג',
        94: 'צ״ד',
        95: 'צ״ה',
        96: 'צ״ו',
        97: 'צ״ז',
        98: 'צ״ח',
        99: 'צ״ט',
        100: 'ק׳',
        101: 'ק״א',
        102: 'ק״ב',
        103: 'ק״ג',
        104: 'ק״ד',
        105: 'ק״ה',
        106: 'ק״ו',
        107: 'ק״ז',
        108: 'ק״ח',
        109: 'ק״ט',
        110: 'ק״י',
        111: 'קי״א',
        112: 'קי״ב',
        113: 'קי״ג',
        114: 'קי״ד',
        115: 'קט״ו',
        116: 'קט״ז',
        117: 'קי״ז',
        118: 'קי״ח',
        119: 'קי״ט',
        120: 'ק״כ',
        121: 'קכ״א',
        122: 'קכ״ב',
        123: 'קכ״ג',
        124: 'קכ״ד',
        125: 'קכ״ה',
        126: 'קכ״ו',
        127: 'קכ״ז',
        128: 'קכ״ח',
        129: 'קכ״ט',
        130: 'ק״ל',
        131: 'קל״א',
        132: 'קל״ב',
        133: 'קל״ג',
        134: 'קל״ד',
        135: 'קל״ה',
        136: 'קל״ו',
        137: 'קל״ז',
        138: 'קל״ח',
        139: 'קל״ט',
        140: 'ק״מ',
        141: 'קמ״א',
        142: 'קמ״ב',
        143: 'קמ״ג',
        144: 'קמ״ד',
        145: 'קמ״ה',
        146: 'קמ״ו',
        147: 'קמ״ז',
        148: 'קמ״ח',
        149: 'קמ״ט',
    }
    try {
        return table[indo_arabic_number]
    } catch (e) {
        console.log("Number must be between 1 and 149.")
    }
    return ''
}

export const indoArabicToHebrew = (number: number): string => {
    const table: TableIndoToHebrew = {
        1: 'א',
        2: 'ב',
        3: 'ג',
        4: 'ד',
        5: 'ה',
        6: 'ו',
        7: 'ז',
        8: 'ח',
        9: 'ט',
        10: 'י',
        11: 'יא',
        12: 'יב',
        13: 'יג',
        14: 'יד',
        15: 'טו',
        16: 'טז',
        17: 'יז',
        18: 'יח',
        19: 'יט',
        20: 'כ',
        21: 'כא',
        22: 'כב',
        23: 'כג',
        24: 'כד',
        25: 'כה',
        26: 'כו',
        27: 'כז',
        28: 'כח',
        29: 'כט',
        30: 'ל',
        31: 'לא',
        32: 'לב',
        33: 'לג',
        34: 'לד',
        35: 'לה',
        36: 'לו',
        37: 'לז',
        38: 'לח',
        39: 'לט',
        40: 'מ',
        41: 'מא',
        42: 'מב',
        43: 'מג',
        44: 'מד',
        45: 'מה',
        46: 'מו',
        47: 'מז',
        48: 'מח',
        49: 'מט',
        50: 'נ',
        51: 'נא',
        52: 'נב',
        53: 'נג',
        54: 'נד',
        55: 'נה',
        56: 'נו',
        57: 'נז',
        58: 'נח',
        59: 'נט',
        60: 'ס',
        61: 'סא',
        62: 'סב',
        63: 'סג',
        64: 'סד',
        65: 'סה',
        66: 'סו',
        67: 'סז',
        68: 'סח',
        69: 'סט',
        70: 'ע',
        71: 'עא',
        72: 'עב',
        73: 'עג',
        74: 'עד',
        75: 'עה',
        76: 'עו',
        77: 'עז',
        78: 'עח',
        79: 'עט',
        80: 'פ',
        81: 'פא',
        82: 'פב',
        83: 'פג',
        84: 'פד',
        85: 'פה',
        86: 'פו',
        87: 'פז',
        88: 'פח',
        89: 'פט',
        90: 'צ',
        91: 'צא',
        92: 'צב',
        93: 'צג',
        94: 'צד',
        95: 'צה',
        96: 'צו',
        97: 'צז',
        98: 'צח',
        99: 'צט',
        100: 'ק',
        101: 'קא',
        102: 'קב',
        103: 'קג',
        104: 'קד',
        105: 'קה',
        106: 'קו',
        107: 'קז',
        108: 'קח',
        109: 'קט',
        110: 'קי',
        111: 'קיא',
        112: 'קיב',
        113: 'קיג',
        114: 'קיד',
        115: 'קטו',
        116: 'קטז',
        117: 'קיז',
        118: 'קיח',
        119: 'קיט',
        120: 'קכ',
        121: 'קכא',
        122: 'קכב',
        123: 'קכג',
        124: 'קכד',
        125: 'קכה',
        126: 'קכו',
        127: 'קכז',
        128: 'קכח',
        129: 'קכט',
        130: 'קל',
        131: 'קלא',
        132: 'קלב',
        133: 'קלג',
        134: 'קלד',
        135: 'קלה',
        136: 'קלו',
        137: 'קלז',
        138: 'קלח',
        139: 'קלט',
        140: 'קמ',
        141: 'קמא',
        142: 'קמב',
        143: 'קמג',
        144: 'קמד',
        145: 'קמה',
        146: 'קמו',
        147: 'קמז',
        148: 'קמח',
        149: 'קמט',
        150: 'קנ',
        151: 'קנא',
        152: 'קנב',
        153: 'קנג',
        154: 'קנד',
        155: 'קנה',
        156: 'קנו',
        157: 'קנז',
        158: 'קנח',
        159: 'קנט',
        160: 'קס',
        161: 'קסא',
        162: 'קסב',
        163: 'קסג',
        164: 'קסד',
        165: 'קסה',
        166: 'קסו',
        167: 'קסז',
        168: 'קסח',
        169: 'קסט',
        170: 'קע',
        171: 'קעא',
        172: 'קעב',
        173: 'קעג',
        174: 'קעד',
        175: 'קעה',
        176: 'קעו',
        177: 'קעז',
        178: 'קעח',
        179: 'קעט',
        180: 'קפ',
        181: 'קפא',
        182: 'קפב',
        183: 'קפג',
        184: 'קפד',
        185: 'קפה',
        186: 'קפו',
        187: 'קפז',
        188: 'קפח',
        189: 'קפט',
        190: 'קצ',
        191: 'קצא',
        192: 'קצב',
        193: 'קצג',
        194: 'קצד',
        195: 'קצה',
        196: 'קצו',
        197: 'קצז',
        198: 'קצח',
        199: 'קצט',
        200: 'ר',
    }
    return table[number]
}

export const hebrewCardinalNumbers: TableHebrewToIndo = {
    'א': 1,
    'ב': 2,
    'ג': 3,
    'ד': 4,
    'ה': 5,
    'ו': 6,
    'ז': 7,
    'ח': 8,
    'ט': 9,
    'י': 10,
    'יא': 11,
    'יב': 12,
    'יג': 13,
    'יד': 14,
    'טו': 15,
    'טז': 16,
    'יז': 17,
    'יח': 18,
    'יט': 19,
    'כ': 20,
    'כא': 21,
    'כב': 22,
    'כג': 23,
    'כד': 24,
    'כה': 25,
    'כו': 26,
    'כז': 27,
    'כח': 28,
    'כט': 29,
    'ל': 30,
    'לא': 31,
    'לב': 32,
    'לג': 33,
    'לד': 34,
    'לה': 35,
    'לו': 36,
    'לז': 37,
    'לח': 38,
    'לט': 39,
    'מ': 40,
    'מא': 41,
    'מב': 42,
    'מג': 43,
    'מד': 44,
    'מה': 45,
    'מו': 46,
    'מז': 47,
    'מח': 48,
    'מט': 49,
    'נ': 50,
    'נא': 51,
    'נב': 52,
    'נג': 53,
    'נד': 54,
    'נה': 55,
    'נו': 56,
    'נז': 57,
    'נח': 58,
    'נט': 59,
    'ס': 60,
    'סא': 61,
    'סב': 62,
    'סג': 63,
    'סד': 64,
    'סה': 65,
    'סו': 66,
    'סז': 67,
    'סח': 68,
    'סט': 69,
    'ע': 70,
    'עא': 71,
    'עב': 72,
    'עג': 73,
    'עד': 74,
    'עה': 75,
    'עו': 76,
    'עז': 77,
    'עח': 78,
    'עט': 79,
    'פ': 80,
    'פא': 81,
    'פב': 82,
    'פג': 83,
    'פד': 84,
    'פה': 85,
    'פו': 86,
    'פז': 87,
    'פח': 88,
    'פט': 89,
    'צ': 90,
    'צא': 91,
    'צב': 92,
    'צג': 93,
    'צד': 94,
    'צה': 95,
    'צו': 96,
    'צז': 97,
    'צח': 98,
    'צט': 99,
    'ק': 100,
    'קא': 101,
    'קב': 102,
    'קג': 103,
    'קד': 104,
    'קה': 105,
    'קו': 106,
    'קז': 107,
    'קח': 108,
    'קט': 109,
    'קי': 110,
    'קיא': 111,
    'קיב': 112,
    'קיג': 113,
    'קיד': 114,
    'קטו': 115,
    'קטז': 116,
    'קיז': 117,
    'קיח': 118,
    'קיט': 119,
    'קכ': 120,
    'קכא': 121,
    'קכב': 122,
    'קכג': 123,
    'קכד': 124,
    'קכה': 125,
    'קכו': 126,
    'קכז': 127,
    'קכח': 128,
    'קכט': 129,
    'קל': 130,
    'קלא': 131,
    'קלב': 132,
    'קלג': 133,
    'קלד': 134,
    'קלה': 135,
    'קלו': 136,
    'קלז': 137,
    'קלח': 138,
    'קלט': 139,
    'קמ': 140,
    'קמא': 141,
    'קמב': 142,
    'קמג': 143,
    'קמד': 144,
    'קמה': 145,
    'קמו': 146,
    'קמז': 147,
    'קמח': 148,
    'קמט': 149,
    'קנ': 150,
    'קנא': 151,
    'קנב': 152,
    'קנג': 153,
    'קנד': 154,
    'קנה': 155,
    'קנו': 156,
    'קנז': 157,
    'קנח': 158,
    'קנט': 159,
    'קס': 160,
    'קסא': 161,
    'קסב': 162,
    'קסג': 163,
    'קסד': 164,
    'קסה': 165,
    'קסו': 166,
    'קסז': 167,
    'קסח': 168,
    'קסט': 169,
    'קע': 170,
    'קעא': 171,
    'קעב': 172,
    'קעג': 173,
    'קעד': 174,
    'קעה': 175,
    'קעו': 176,
    'קעז': 177,
    'קעח': 178,
    'קעט': 179,
    'קפ': 180,
    'קפא': 181,
    'קפב': 182,
    'קפג': 183,
    'קפד': 184,
    'קפה': 185,
    'קפו': 186,
    'קפז': 187,
    'קפח': 188,
    'קפט': 189,
    'קצ': 190,
    'קצא': 191,
    'קצב': 192,
    'קצג': 193,
    'קצד': 194,
    'קצה': 195,
    'קצו': 196,
    'קצז': 197,
    'קצח': 198,
    'קצט': 199,
    'ר': 200,
}

export const hebrewToIndoArabic = (hebrew_number: string): number => {
    hebrew_number = hebrew_number.trim()
    // first try cardinal numbers
    let translate = hebrewCardinalNumbers[hebrew_number]
    if (translate === undefined)
        // second try ordinal numbers
        translate = hebrewOrdinalToIndoArabic(hebrew_number)
    return translate

}

export const hebrewOrdinalsNumbers: TableHebrewToIndo = {
    'א׳': 1,
    'ב׳': 2,
    'ג׳': 3,
    'ד׳': 4,
    'ה׳': 5,
    'ו׳': 6,
    'ז׳': 7,
    'ח׳': 8,
    'ט׳': 9,
    'י׳': 10,
    'י״א': 11,
    'י״ב': 12,
    'י״ג': 13,
    'י״ד': 14,
    'ט״ו': 15,
    'ט״ז': 16,
    'י״ז': 17,
    'י״ח': 18,
    'י״ט': 19,
    'כ׳': 20,
    'כ״א': 21,
    'כ״ב': 22,
    'כ״ג': 23,
    'כ״ד': 24,
    'כ״ה': 25,
    'כ״ו': 26,
    'כ״ז': 27,
    'כ״ח': 28,
    'כ״ט': 29,
    'ל׳': 30,
    'ל״א': 31,
    'ל״ב': 32,
    'ל״ג': 33,
    'ל״ד': 34,
    'ל״ה': 35,
    'ל״ו': 36,
    'ל״ז': 37,
    'ל״ח': 38,
    'ל״ט': 39,
    'מ׳': 40,
    'מ״א': 41,
    'מ״ב': 42,
    'מ״ג': 43,
    'מ״ד': 44,
    'מ״ה': 45,
    'מ״ו': 46,
    'מ״ז': 47,
    'מ״ח': 48,
    'מ״ט': 49,
    'נ׳': 50,
    'נ״א': 51,
    'נ״ב': 52,
    'נ״ג': 53,
    'נ״ד': 54,
    'נ״ה': 55,
    'נ״ו': 56,
    'נ״ז': 57,
    'נ״ח': 58,
    'נ״ט': 59,
    'ס׳': 60,
    'ס״א': 61,
    'ס״ב': 62,
    'ס״ג': 63,
    'ס״ד': 64,
    'ס״ה': 65,
    'ס״ו': 66,
    'ס״ז': 67,
    'ס״ח': 68,
    'ס״ט': 69,
    'ע׳': 70,
    'ע״א': 71,
    'ע״ב': 72,
    'ע״ג': 73,
    'ע״ד': 74,
    'ע״ה': 75,
    'ע״ו': 76,
    'ע״ז': 77,
    'ע״ח': 78,
    'ע״ט': 79,
    'פ׳': 80,
    'פ״א': 81,
    'פ״ב': 82,
    'פ״ג': 83,
    'פ״ד': 84,
    'פ״ה': 85,
    'פ״ו': 86,
    'פ״ז': 87,
    'פ״ח': 88,
    'פ״ט': 89,
    'צ׳': 90,
    'צ״א': 91,
    'צ״ב': 92,
    'צ״ג': 93,
    'צ״ד': 94,
    'צ״ה': 95,
    'צ״ו': 96,
    'צ״ז': 97,
    'צ״ח': 98,
    'צ״ט': 99,
    'ק׳': 100,
    'ק״א': 101,
    'ק״ב': 102,
    'ק״ג': 103,
    'ק״ד': 104,
    'ק״ה': 105,
    'ק״ו': 106,
    'ק״ז': 107,
    'ק״ח': 108,
    'ק״ט': 109,
    'ק״י': 110,
    'קי״א': 111,
    'קי״ב': 112,
    'קי״ג': 113,
    'קי״ד': 114,
    'קט״ו': 115,
    'קט״ז': 116,
    'קי״ז': 117,
    'קי״ח': 118,
    'קי״ט': 119,
    'ק״כ': 120,
    'קכ״א': 121,
    'קכ״ב': 122,
    'קכ״ג': 123,
    'קכ״ד': 124,
    'קכ״ה': 125,
    'קכ״ו': 126,
    'קכ״ז': 127,
    'קכ״ח': 128,
    'קכ״ט': 129,
    'ק״ל': 130,
    'קל״א': 131,
    'קל״ב': 132,
    'קל״ג': 133,
    'קל״ד': 134,
    'קל״ה': 135,
    'קל״ו': 136,
    'קל״ז': 137,
    'קל״ח': 138,
    'קל״ט': 139,
    'ק״מ': 140,
    'קמ״א': 141,
    'קמ״ב': 142,
    'קמ״ג': 143,
    'קמ״ד': 144,
    'קמ״ה': 145,
    'קמ״ו': 146,
    'קמ״ז': 147,
    'קמ״ח': 148,
    'קמ״ט': 149,
}

export const hebrewOrdinalToIndoArabic = (hebrew_ordinal: string): number => hebrewOrdinalsNumbers[hebrew_ordinal]

